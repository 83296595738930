import { Modal } from 'bootstrap';

const template = `
<div class="modal-dialog modal-fullscreen modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 id="image-modal-title" class="modal-title"></h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body"></div>
  </div>
</div>
`;

const modalEl = document.createElement('div');
modalEl.classList.add('modal');
modalEl.classList.add('fade');
modalEl.tabIndex = -1;
modalEl.ariaLabelledBy = '#image-modal-title';
modalEl.ariaHidden = true;
modalEl.innerHTML = template;
const titleEl = modalEl.querySelector('.modal-title');
const bodyEl = modalEl.querySelector('.modal-body');

document.body.appendChild(modalEl);

const modal = new Modal(modalEl);

export default function (title, body) {
  titleEl.textContent = title;
  bodyEl.innerHTML = body;
  modal.show();
}
