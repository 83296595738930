/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file
import './scss/frontend.scss';

import { Collapse, Dropdown, Alert, Offcanvas } from 'bootstrap';

import NiceSelect from '../../node_modules/nice-select2/src/js/nice-select2.js';

import FullImage from './js/full-image.js';

window.OHMedia = {
  NiceSelect,
  FullImage,
};
